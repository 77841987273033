<template>
</template>

<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Banner },
  mixins: [adminMixin],
  props: {
    latestChatwootVersion: { type: String, default: '' },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
  },
  methods: {
  },
};
</script>
